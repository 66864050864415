<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Login</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <b-button
            icon-left="filter-variant"
            class="is-command"
            @click="toggleFilter"
          >
            Filter
          </b-button>
          <b-input
            icon-right="magnify"
            rounded
            v-model="search"
            placeholder="Search"
            @keydown.native.enter="searchIconClick(search)"
            icon-clickable
            @icon-right-click="searchIconClick(search)"
            icon-right-clickable
            :loading="isLoading"
          >
            Search
          </b-input>
        </div>
      </div>
    </template>
    <template #page-content>
      <div class="filter-card" v-if="isFilterOpen">
        <form>
          <h4 class="filter-title">Filter</h4>
          <div class="reset-filter" @click="resetFilter">Reset</div>
          <div class="columns is-multiple">
            <div class="column is-6">
              <div class="is-12 filter-subtitle mb-3">Start Date</div>
              <div class="is-12">
                <b-datepicker
                  v-model="startDate"
                  placeholder="Start Date"
                  size="is-size-5"
                ></b-datepicker>
              </div>
            </div>
            <div class="column is-6">
              <div class="is-12 filter-subtitle mb-3">End Date</div>
              <div class="is-12">
                <b-datepicker
                  v-model="endDate"
                  placeholder="End Date"
                  size="is-size-5"
                ></b-datepicker>
              </div>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-12">
              <b-button
                class="is-fullwidth"
                @click.native="submit(startDate, endDate)"
                :loading="isLoading"
              >
                Search
              </b-button>
            </div>
          </div>
        </form>
      </div>
      <b-table
        :data="data ? data.data : []"
        :loading="isLoading"
        paginated
        backend-pagination
        :total="data && data.meta ? data.meta.total : {}"
        :per-page="perPage"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :current-page.sync="page"
        hoverable
        backend-sorting
        @sort="onSort"
        class="table-timeoff-type table-request-personal"
      >
        <b-table-column field="no" label="No" v-slot="props" sortable>
          {{ props.index + data.meta.from }}
        </b-table-column>
        <b-table-column field="email" label="Email" v-slot="props" sortable>
          {{ props.row.companyUser.user.email }}
        </b-table-column>
        <b-table-column field="type" label="Type" sortable v-slot="props">
          {{ props.row.type }}
        </b-table-column>
        <b-table-column field="created_at" label="Time" sortable v-slot="props">
          {{
            props.row.createdAt
              ? formatDateTime(props.row.createdAt, 'DD/MM/YY HH:mm')
              : '-'
          }}
        </b-table-column>
        <b-table-column field="device" label="Device" v-slot="props" sortable>
          {{ props.row.device }}
        </b-table-column>
        <b-table-column field="os" label="OS" v-slot="props" sortable>
          {{ props.row.os }}
        </b-table-column>
        <b-table-column label="IP Address" v-slot="props">
          {{ props.row.ip }}
        </b-table-column>
      </b-table>
    </template>
  </r-page>
</template>
<script>
import ApiService from '@/services/common/api.service'
import { formatDate } from '@/services/util'
import moment from 'moment'

export default {
  data() {
    return {
      isFilterOpen: false,
      isFilterDate: false,
      deviceLists: null,
      osLists: null,
      deviceIds: [],
      osIds: [],
      search: '',
      isLoading: false,
      page: 1,
      perPage: 10,
      lastPage: 1,
      total: 0,
      orderBy: 'created_at',
      orderDirection: 'asc',
      startDate: null,
      endDate: null,

      data: {
        meta: {},
        data: [],
      },
    }
  },

  methods: {
    async getLogUser(
      search,
      orderBy,
      orderDirection,
      page,
      perPage,
      startDate,
      endDate
    ) {
      let searchQuery = `${search}&order_by=${orderBy}&order_direction=${orderDirection}&page=${page}&per_page=${perPage}`
      if (this.isFilterDate) {
        searchQuery += `&ranges=${startDate},${endDate}`
      }
      await ApiService.get(
        `/auth/logger/company-user?&search=${searchQuery}`
      ).then((response) => {
        this.data.data = response.data.data
        this.data.meta = response.data.meta
        let currentTotal = this.data.meta.total
        if (this.data.meta.total / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        this.isLoading = false
      })
    },
    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen
    },

    resetFilter() {
      this.isFilterDate = false
      this.isFilterOpen = false
      this.startDate = null
      this.endDate = null
      this.getLogUser(
        this.search,
        this.orderBy,
        this.orderDirection,
        this.page,
        this.perPage,
        this.startDate,
        this.endDate
      )
    },

    async submit(startDate, endDate) {
      this.isFilterDate = true
      let rangeStart = moment(startDate).format('YYYY-MM-DD')
      let rangeEnd = moment(endDate).format('YYYY-MM-DD')

      this.isLoading = true
      this.getLogUser(
        this.search,
        this.orderBy,
        this.orderDirection,
        this.page,
        this.perPage,
        rangeStart,
        rangeEnd
      )
      this.isFilterOpen = false
    },

    formatDateTime(date, format) {
      return formatDate(date, format)
    },

    searchIconClick(search) {
      this.isLoading = true
      this.getLogUser(
        search,
        this.orderBy,
        this.orderDirection,
        this.page,
        this.perPage,
        this.startDate,
        this.endDate
      )
    },

    onPageChange(page) {
      let rangeStart = moment(this.startDate).format('YYYY-MM-DD')
      let rangeEnd = moment(this.endDate).format('YYYY-MM-DD')
      this.page = page
      this.getLogUser(
        this.search,
        this.orderBy,
        this.orderDirection,
        this.page,
        this.perPage,
        rangeStart,
        rangeEnd
      )
    },

    onSort(field, order) {
      this.orderBy = field
      this.orderDirection = order
      this.page = 1
      this.perPage = 10
      this.data.data = []
      this.isLoading = true
      this.getLogUser(
        this.search,
        this.orderBy,
        this.orderDirection,
        this.page,
        this.perPage,
        this.startDate,
        this.endDate
      )
    },
  },

  mounted() {
    this.getLogUser(
      this.search,
      this.orderBy,
      this.orderDirection,
      this.page,
      this.perPage,
      this.startDate,
      this.endDate
    )
  },
}
</script>
